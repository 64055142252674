<template>
  <b-nav-item-dropdown
    style="list-style: none"
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
        style="margin-left: 3.5em"
      />
      <!-- <span class="ml-50 text-body">{{ currentLocale.name }}</span> -->
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="$i18n.locale = localeObj.locale"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      lg: "",
      locales: [
        {
          locale: "th",
          img: require("@/assets/images/flags/th.png"),
          name: "ภาษาไทย",
        },
        {
          locale: "en",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
      ],
    };
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  mounted() {
    if(this.$i18n.locale == "th"){
     this.currentLocale.img =  require("@/assets/images/flags/th.png")
    }else{
      this.currentLocale.img =  require("@/assets/images/flags/en.png")

    }

  },
  watch: {
    currentLocale: {
      handler: function (val, oldVal) {

        this.$i18n.locale = val.locale;

      },
      deep: true,
    },
  },

};
</script>

<style>
</style>
