<template>
  <div>
    <b-row>
      <b-col  md="12" xl="7" lg="7">
        <b-row>
          <b-col cols="12">
            <b-card>
              <b>ตั้งค่าเวลาใช้งาน</b>
              <div style="margin-left: 88%; margin-top: -25px">
                <b-button variant="primary" @click="save()">
                  <span>บันทึก</span>
                </b-button>
              </div>
              <b>กลุ่มเป้าหมาย</b>
              <hr>
              <b-row>
                <b-col cols="6"> ช่วงอายุ <br><br>
                  <div>
                    <vue-slider
                    v-model="age"
                    :direction="direction"
                    class="mb-2 vue-slider-success"
                  />
                  
                  </div>
                </b-col>
                <b-col cols="6"> เพศ 
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="gender"
                      name="gender"
                      value="all"
                      class="custom-control-primary"
                    >
                      ทั้งหมด
                    </b-form-radio>
                    <b-form-radio
                      v-model="gender"
                      name="gender"
                      value="male"
                      class="custom-control-primary"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="gender"
                      name="gender"
                      value="female"
                      class="custom-control-primary"
                    >
                      หญิง
                    </b-form-radio>
                  </div>


                </b-col>
              </b-row>
            <hr>
              <b-row>
                <b-col cols="2" style="margin-top: 2em">เริ่ม</b-col>
                <b-col cols="3">
                  <b-form-group>
                    <h5>วัน</h5>
                    <flat-pickr v-model="date_start" class="form-control" />
                  </b-form-group>
                  <b-alert
                    v-model="check.date_start"
                    v-height-fade.appear
                    variant="danger"
                    dismissible
                    class="mb-0"
                  >
                    <div class="alert-body">ใส่วันเริ่ม</div>
                  </b-alert>
                </b-col>
                <b-col cols="3">
                  <b-form-group>
                    <h5>เวลา</h5>
                    <flat-pickr
                      v-model="time_start"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                      }"
                    />
                  </b-form-group>
                  <b-alert
                    v-model="check.time_start"
                    v-height-fade.appear
                    variant="danger"
                    dismissible
                    class="mb-0"
                  >
                    <div class="alert-body">ใส่เวลาเริ่ม</div>
                  </b-alert>
                </b-col>
                <b-col cols="4" style="margin-top: 2em">
                  <div>
                    ไม่มีวันสิ้นสุด
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      v-model="day"
                      inline
                    />
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="day == 'false' || day == false">
                <b-col cols="2" style="margin-top: 2em">สิ้นสุด</b-col>
                <b-col cols="3">
                  <b-form-group>
                    <h5>วัน</h5>
                    <flat-pickr v-model="date_end" class="form-control" />
                  </b-form-group>

                  <b-alert
                    v-model="check.date_start"
                    v-height-fade.appear
                    variant="danger"
                    dismissible
                    class="mb-0"
                  >
                    <div class="alert-body">ใส่วันสิ้นสุด</div>
                  </b-alert>
                </b-col>
                <b-col cols="3">
                  <b-form-group>
                    <h5>เวลา</h5>
                    <flat-pickr
                      v-model="time_end"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                      }"
                    />
                  </b-form-group>
                  <b-alert
                    v-model="check.time_start"
                    v-height-fade.appear
                    variant="danger"
                    dismissible
                    class="mb-0"
                  >
                    <div class="alert-body">ใส่เวลาสิ้นสุด</div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-alert
                v-model="check.time_error"
                v-height-fade.appear
                variant="danger"
                dismissible
                class="mb-0"
              >
                <div class="alert-body">เวลาผิดพลาด</div>
              </b-alert>
              <b-alert
                v-model="check.date_error"
                v-height-fade.appear
                variant="danger"
                dismissible
                class="mb-0"
              >
                <div class="alert-body">วันที่ผิดพลาด</div>
              </b-alert>

              <hr />
              <b>เลือกการ์ดเมสเสจ</b><br /><br />
              <div>
                <b-button variant="outline-primary" v-b-modal.modal-type>
                  <span>เลือกการ์ดเมสเสจ</span>
                </b-button>
                <b-alert
                  v-model="check.show_id"
                  v-height-fade.appear
                  variant="danger"
                  dismissible
                  class="mb-0"
                >
                  <div class="alert-body">เลือกการ์ดเมสเสจ</div>
                </b-alert>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col  md="12" xl="5" lg="5">
        <previwe ref="datapreviwe"></previwe>
      </b-col>
    </b-row>
    <b-modal
      v-model="model_type"
      id="modal-type"
      centered
      title="เลือกการ์ด"
      ok-only
      ok-variant="danger"
      ok-title="ยกเลิก"
      size="lg"
    >
      <!-- ScrollArea: Chat & Contacts -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="chat-user-list-wrapper list-group scroll-area"
      >
        <ul class="chat-users-list chat-list media-list">
          <div
            v-for="(row, index) in rows"
            :id="row.id"
            :key="row.id"
            ref="row"
          >
            <b-list-group
              @click="
                set_and_previwe(
                  row.id,
                  row.name_card,
                  row.un_items,
                  row.un_items_end,
                  row.type_card
                )
              "
            >
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <h4>{{ index + 1 }}. {{ row.name_card }}</h4>
                <br />
                <h5>{{ row.count_items }}/{{ row.type_card }}</h5>
              </b-list-group-item>
            </b-list-group>
          </div>
        </ul>
      </vue-perfect-scrollbar>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BListGroup,
  BListGroupItem,
  VBModal,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { heightFade } from "@core/directives/animations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import previwe from "./preview.vue";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueSlider from 'vue-slider-component'
import store from '@/store/index'
export default {
  components: {
    VueSlider,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BAlert,
    BFormCheckbox,
  BFormRadio,

    // 3rd party
    VuePerfectScrollbar,
    previwe,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      date_start: "",
      time_start: "",
      date_end: "",
      time_end: "",
      totalRows: "",
      rows: [],
      items: [],
      items_end: [],
      model_type: false,
      day: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      check: {
        date_start: false,
        time_start: false,
        date_end: false,
        show_id: false,
        time_error: false,
        date_error: false,
      },
      age: [0, 50],
      gender: "",
      dir: "ltr",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },

  mounted() {
    this.loaddata();
    this.loaddata_edit();
  },
  watch: {
    day: {
      handler: function (val, oldVal) {
        console.log("oldVal :>> ", oldVal);
        console.log("val :>> ", val);
        const d = new Date();
        let year = d.getFullYear();
        if (val === true) {
          this.date_end = year + "-12-31";
          this.time_end = "23:59:59";
        }
      },
      deep: true,
    },
  },
  methods: {
    async loaddata_edit() {
      const params = {
        id: this.$route.query.id,
      };
      console.log("params :>> ", params);
      api
        .post("edit_splashpage", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.show_id = response.data.show_id;
          this.name = response.data.name;
          this.type = response.data.type;
          this.date_start = response.data.date_start;
          this.time_start = response.data.time_start;
          this.date_end = response.data.date_end;
          this.time_end = response.data.time_end;
          this.day = response.data.day_end;
          this.gender = response.data.gender;
          this.age = response.data.age;
          this.set_and_previwe(
            response.data.show_id,
            response.data.name,
            response.data.items,
            response.data.items_end,
            response.data.type_card
          );
        });
    },
    async loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_card_message", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.rows = response.data.data;
          this.totalRows = response.data.total;
        });
    },
    set_and_previwe(show_id, name_card, items, items_end, type_card) {
      console.log("show_id :>> ", show_id);
      console.log("name_card :>> ", name_card);
      console.log("items :>> ", items);
      console.log("items_end :>> ", items_end);
      console.log("type_card :>> ", type_card);
      this.model_type = false;
      this.$refs.datapreviwe.data_preview(items, items_end, type_card);
      this.show_id = show_id;
      this.type = type_card;
      this.name = name_card;
      console.log("this.show_id :>> ", this.show_id);
    },
    save() {
      console.log("this.show_id :>> ", this.show_id);
      console.log("this.day :>> ", this.day);
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "ตรวจสอบข้อมูล",
            icon: "CheckIcon",
            text: "กำลังตรวจสอบข้อมูล",
            variant: "warning",
          },
        },
        {
          timeout: 2000,
        }
      );
      if (
        this.show_id &&
        this.date_start &&
        this.time_start &&
        this.date_end &&
        this.time_end
      ) {
        if (Date.parse(this.date_start) === Date.parse(this.date_end)) {
          if (
            parseInt(this.time_start.substring(0, 2)) >
            parseInt(this.time_end.substring(0, 2))
          ) {
            this.check.time_error = true;
          } else {
            this.insert();
          }
        }
        if (Date.parse(this.date_start) < Date.parse(this.date_end)) {
          this.insert();
        }
        if (Date.parse(this.date_start) > Date.parse(this.date_end)) {
          this.check.date_error = true;
        }
      }
      if (!this.show_id) {
        this.check.show_id = true;
      }
      if (!this.date_start) {
        this.check.date_start = true;
      }
      if (!this.date_end) {
        this.check.date_end = true;
      }
      if (!this.time_start) {
        this.check.time_start = true;
      }
      if (!this.time_end) {
        this.check.time_end = true;
      }
    },
    insert() {
      const params = {
        id: this.$route.query.id,
        show_id: this.show_id,
        name: this.name,
        type: this.type,
        date_start: this.date_start,
        time_start: this.time_start,
        date_end: this.date_end,
        time_end: this.time_end,
        day_end: this.day,
        gender: this.gender,
        age: this.age,
        store_id: localStorage.getItem("store_id"),
      };
      console.log("params :>> ", params);
      api
        .post("update_splashpage", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "แก้ไขข้อมูล",
                icon: "SaveIcon",
                text: "บันทึกข้อมูลของคุณเรียบร้อย",
                variant: "success",
              },
            },
            {
              timeout: 4000,
            }
          );
          setTimeout(() => {
            this.$router.push({
              path: "/splash",
            });
          }, "2000");
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
