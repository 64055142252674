<template>
  <div style="margin-left: -20px;">
    <div id="iphone">
      <div id="screen">
        <div id="notch" style="background-color: #000">
          <div style="margin-left: -40px; font-size: 12px; padding-top: 5px">
            <p style="color: #000; margin-right: 10em">Dtac</p>
          </div>
          <div id="speaker" style="margin-top: -40px"></div>
        </div>
        <div id="stat-bar" style="margin-top: -5px">
          <feather-icon
            icon="WifiIcon"
            style="color: #000"
            class="mr-50"
            size="15"
          />
          <feather-icon
            icon="BatteryChargingIcon"
            style="color: #000"
            class="mr-90"
            size="15"
          />
        </div>

        <br />
        <div id="flex2" v-if="type_card === 'รูปภาพ'">
          <div class="LySlider">
            <div class="lyInner">
              <div
                class="lyItem LyMe"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <div class="T1 fxLTR" dir="ltr">
                  <div class="t1Hero"></div>
                  <div class="t1Header"></div>
                  <div class="t1Body">
                    <div class="MdBx vr" style="padding: 0px">
                      <div class="MdImg ExCover ExFull">
                        <div>
                          <a style="padding-bottom: 100%">
                            <span
                              :style="{
                                'background-image':
                                  'url(' + item.pictrue1 + ')',
                              }"
                            ></span>
                          </a>
                        </div>
                      </div>

                      <div
                        :id="'text_banner' + index"
                        class="MdBx vr ExAbs"
                        :class="'inner_position_top ' + item.setclassBanner"
                        v-if="item.text_banner"
                        style="
                          width: 85px;
                          max-width: 85px;
                          height: 25px;
                          border-radius: 20px;
                          top: 18px;
                          left: 18px;
                        "
                      >
                        <div class="MdTxt ExAlgC ExXs" style="margin-top: -2px">
                          <p>{{ item.text_banner }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="t1Footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div id="flex5" v-if="type_card === 'สินค้า'">
          <div class="LySlider">
            <div class="lyInner">
              <div
                class="lyItem LyMe"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <div
                  class="T1 fxLTR"
                  dir="ltr"
                  v-if="item.number_of_pictures === 1"
                >
                  <div class="t1Hero"></div>
                  <div class="t1Header">
                    <div class="MdBx vr" style="padding: 0px">
                      <div class="MdBx hr">
                        <div class="MdImg ExCover fl1 ExFull grvC">
                          <div>
                            <a style="padding-bottom: 65%">
                              <span
                                :style="{
                                  'background-image':
                                    'url(' + item.pictrue1 + ')',
                                }"
                              ></span>
                            </a>
                          </div>
                        </div>

                        <div
                          class="MdBx hr fl0 ExAbs"
                          :id="'text_banner' + index"
                          v-if="item.text_banner"
                          :class="'inner_position_top ' + item.setclassBanner"
                          style="
                            width: auto;
                            max-width: 90px;
                            height: 25px;

                            border-radius: 100px;
                            top: 18px;
                            left: 18px;
                            padding: 2px;
                            padding-left: 4px;
                            padding-right: 4px;
                          "
                        >
                          <div class="ExAlgC grvC ExXs">
                            <p>{{ item.text_banner }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="t1Body">
                    <div
                      class="MdBx vr"
                      style="background-color: #fff !important ; padding: 20px"
                    >
                      <div align="left">
                        <h3>{{ item.name }}</h3>
                      </div>
                      <div align="left">
                        <p>{{ item.description }}</p>
                      </div>
                      <div align="right">฿ {{ item.price }}</div>
                    </div>
                  </div>
                  <div class="t1Footer"></div>
                </div>
                <div
                  class="T1 fxLTR"
                  dir="ltr"
                  v-if="item.number_of_pictures === 2"
                >
                  <div class="t1Hero"></div>
                  <div class="t1Header">
                    <div class="MdBx vr" style="padding: 0px">
                      <div class="MdBx hr">
                        <div class="MdImg ExCover fl1 ExFull grvC">
                          <div>
                            <a style="padding-bottom: 130.66666666666666%">
                              <span
                                :style="{
                                  'background-image':
                                    'url(' + item.pictrue1 + ')',
                                }"
                              ></span>
                            </a>
                          </div>
                        </div>
                        <div class="MdImg ExCover fl1 ExFull grvC">
                          <div>
                            <a style="padding-bottom: 130.66666666666666%">
                              <span
                                :style="{
                                  'background-image':
                                    'url(' + item.pictrue2 + ')',
                                }"
                              ></span>
                            </a>
                          </div>
                        </div>

                        <div
                          class="MdBx hr fl0 ExAbs inner_position_top a5"
                          :id="'text_banner' + index"
                          v-if="item.text_banner"
                          style="
                            width: auto;
                            max-width: 90px;
                            height: 25px;
                            border-radius: 100px;
                            top: 18px;
                            left: 18px;
                            padding: 2px;
                            padding-left: 4px;
                            padding-right: 4px;
                          "
                        >
                          <div class="ExAlgC grvC ExXs">
                            <p>{{ item.text_banner }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="t1Body">
                    <div
                      class="MdBx vr"
                      style="background-color: #fff !important ; padding: 20px"
                    >
                      <div align="left">
                        <h3>{{ item.name }}</h3>
                      </div>
                      <div align="left">
                        <p>{{ item.description }}</p>
                      </div>
                      <div align="right">฿ {{ item.price }}</div>
                    </div>
                  </div>
                  <div class="t1Footer"></div>
                </div>
                <div
                  class="T1 fxLTR"
                  dir="ltr"
                  v-if="item.number_of_pictures === 3"
                >
                  <div class="t1Hero"></div>
                  <div class="t1Header">
                    <div class="MdBx vr" style="padding: 0px">
                      <div class="MdBx hr">
                        <div class="MdImg ExCover fl1 ExFull grvC">
                          <div>
                            <a style="padding-bottom: 130.66666666666666%">
                              <span
                                :style="{
                                  'background-image':
                                    'url(' + item.pictrue1 + ')',
                                }"
                              ></span>
                            </a>
                          </div>
                        </div>
                        <div class="MdBx vr fl1">
                          <div class="MdImg ExCover ExFull grvC">
                            <div>
                              <a style="padding-bottom: 65.33333333333333%">
                                <span
                                  :style="{
                                    'background-image':
                                      'url(' + item.pictrue2 + ')',
                                  }"
                                ></span>
                              </a>
                            </div>
                          </div>
                          <div class="MdImg ExCover ExFull grvC">
                            <div>
                              <a style="padding-bottom: 65.33333333333333%">
                                <span
                                  :style="{
                                    'background-image':
                                      'url(' + item.pictrue3 + ')',
                                  }"
                                ></span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="MdBx hr fl0 ExAbs inner_position_top a5"
                          :id="'text_banner' + index"
                          v-if="item.text_banner"
                          :class="'inner_position_top ' + item.setclassBanner"
                          style="
                            width: auto;
                            max-width: 90px;
                            height: 25px;

                            border-radius: 100px;
                            top: 18px;
                            left: 18px;
                            padding: 2px;
                            padding-left: 4px;
                            padding-right: 4px;
                          "
                        >
                          <div class="ExAlgC grvC ExXs">
                            <p>{{ item.text_banner }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="t1Body">
                    <div
                      class="MdBx vr"
                      style="background-color: #fff !important ; padding: 20px"
                    >
                      <div align="left">
                        <h3>{{ item.name }}</h3>
                      </div>
                      <div align="left">
                        <p>{{ item.description }}</p>
                      </div>
                      <div align="right">฿ {{ item.price }}</div>
                    </div>
                  </div>
                  <div class="t1Footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div id="flex6" v-if="type_card === 'บุคคล'">
          <div class="LySlider">
            <div class="lyInner">
              <div
                class="lyItem LyMe"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <div class="T1 fxLTR" dir="ltr">
                  <div class="t1Hero"></div>
                  <div class="t1Header">
                    <div align="center" class="mt-2 mb-2">
                      <img
                        :src="item.pictrue1"
                        width="120px"
                        height="120px"
                        style="border-radius: 50%"
                      />
                    </div>
                  </div>
                  <div class="t1Body">
                    <h3 v-if="item.name" class="mb-1">
                      {{ item.name }}
                    </h3>
                    <div
                      align="center"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div
                        v-if="item.tag1"
                        :id="'tag1' + index"
                        :class="'mr-1 ' + item.tag_class1"
                        style="
                          width: 63px;
                          max-width: 63px;
                          height: 25px;

                          border-radius: 20px;
                          top: 18px;
                          left: 18px;
                          float: left !important;
                        "
                      >
                        <div class="MdTxt ExAlgC ExXs" style="top: 3px">
                          <p>{{ item.tag1 }}</p>
                        </div>
                      </div>
                      <div
                        v-if="item.tag2"
                        :id="'tag2' + index"
                        :class="'mr-1 ' + item.tag_class2"
                        style="
                          width: 63px;
                          max-width: 63px;
                          height: 25px;

                          border-radius: 20px;
                          top: 18px;
                          left: 18px;
                          float: left !important;
                        "
                      >
                        <div class="MdTxt ExAlgC ExXs" style="top: 3px">
                          <p>{{ item.tag2 }}</p>
                        </div>
                      </div>
                      <div
                        v-if="item.tag3"
                        :id="'tag3' + index"
                        :class="'mr-1 ' + item.tag_class3"
                        style="
                          width: 63px;
                          max-width: 63px;
                          height: 25px;

                          border-radius: 20px;
                          top: 18px;
                          left: 18px;
                          float: left !important;
                        "
                      >
                        <div class="MdTxt ExAlgC ExXs" style="top: 3px">
                          <p>{{ item.tag3 }}</p>
                        </div>
                      </div>
                    </div>
                    <br />
                    <h6>{{ item.description }}</h6>
                    <br /><br />
                  </div>
                  <div class="t1Footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BBadge,
  BCardBody,
  BFormSelect,
  BAlert,
  BFormFile,
  BImgLazy,
  BTabs,
  BTab,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Locale from "./Locale.vue";
import api from "@/api";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BBadge,
    BCardBody,
    BFormSelect,
    BAlert,
    BFormFile,
    Swiper,
    SwiperSlide,
    BImgLazy,
    BTabs,
    BTab,
    BNavItemDropdown,
    BDropdownItem,
    Locale,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      items: [
        {
          id: 1,
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "banner",
          name: "",
          tag1: "tag1",
          tag2: "tag2",
          tag3: "tag3",
          description: "description",
          link: "link",
          price: "999",
          type: "สินค้า",
          setclassBanner: "a5",
          setclassTag: "",
          tag_class1: "a2",
          tag_class2: "a3",
          tag_class3: "a5",
        },
        {
          id: 2,
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "banner",
          name: "",
          tag1: "tag1",
          tag2: "tag2",
          tag3: "tag3",
          description: "description",
          link: "link",
          price: "999",
          type: "สินค้า",
          setclassBanner: "a5",
          setclassTag: "",
          tag_class1: "a2",
          tag_class2: "a3",
          tag_class3: "a5",
        },
      ],
      items_end: [],
      swiperOptions: {
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
      },
      type_card: "สินค้า",
      store_name: localStorage.getItem("store_name"),
      logo: localStorage.getItem("logo"),
      isCaptive: false,
      isSamsung: false,
      liff: "",
      status: "",
      password: "",
      userEmail: "",
      required,
      email,
      name_user: "",
      mac: null,
      hotspot: null,
      data_store: null,
      brach_id: null,
      branch_name: null,
      store_id: null,

      branch_pictrue: null,
      state: null,
      state_socio: null,
      setLogin: false,
      display: "display: none",
      app_ch_id: null,
      app_ch_secret: null,
      lineoa_id: null,
      ch_id: null,
      ch_secret: null,
      ch_token: null,
      href: null,
      youLiked: false,
      Liked: false,
      total_Liked: 30,
      count: 0,
      show_clickTab1: false,
      show_clickTab2: false,
      show_clickTab3: false,
    };
  },
  directives: {
    Ripple,
  },

  created() {},
  mounted() {
    this.youLiked = true;
    this.Liked = true;
    this.close();
    this.store_name = localStorage.getItem("store_name");
  },
  methods: {
    data_preview(items, items_end, type_card) {
      this.items = [];
      this.items = items;
      this.type_card = type_card;
      this.$forceUpdate();
    },
    close() {
      document.getElementById("vdo_ads").style.display = "none";
      document.getElementById("myVideo").pause();
    },
    open() {
      // document.getElementById("vdo_ads").style.display = "";
      // document.getElementById("myVideo").play();
    },

    like() {
      this.count++;
      console.log("this.youLiked :>> ", this.youLiked);
      setTimeout(() => {
        this.count = 0;
      }, "400");
      if (this.count === 2) {
        this.youLiked = false;
        if (this.Liked === false) {
          this.Liked = true;
          this.total_Liked++;
          console.log("this.total_Liked :>> ", this.total_Liked);
        }
        setTimeout(() => {
          this.youLiked = true;
          this.count = 0;
        }, "1000");
      }
    },
    don_like() {
      if (this.total_Liked !== 0) {
        this.total_Liked--;
        this.Liked = false;
      }
    },
    login() {
      // var url = this.href;
      // window.location.href = url;
    },

    clickTab1() {
      this.display = true;
      this.show_clickTab1 = true;
      this.show_clickTab2 = false;
      this.show_clickTab3 = false;
    },
    clickTab2() {
      this.display = true;
      this.show_clickTab1 = false;
      this.show_clickTab2 = true;
      this.show_clickTab3 = false;
    },
    clickTab3() {
      this.display = true;
      this.show_clickTab1 = false;
      this.show_clickTab2 = false;
      this.show_clickTab3 = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
// .swiper-slide {
//   width: 360px !important;
//   margin-right: 10px;
// }

// body {
//   background: white;
//   font-family: Arial;
//   padding: 30px;
// }

.header_wifi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_link {
  display: flex;
  justify-content: center;
  align-items: center;
}

#gesture-bar {
  background: rgb(144, 144, 144);
  width: 30%;
  height: 5px;
  border-radius: 5px;
  margin-left: 35%;
  display: flex;
  align-items: center;
  transition: all 1s;

  &:hover {
    cursor: pointer;
    background: black;
  }
}

#container {
  display: flex;
}

#settings {
  padding: 16px;
  border-radius: 5px;
  width: 30%;

  p {
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
  }

  .round-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .round {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }
  }
}

#iphone {
  $body-color: #000;

  font-family: Arial;
  margin: auto;
  width: 410px;
  height: 812px;
  background: $body-color;
  border-radius: 50px;
  box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.9);
  padding: 16px;

  #screen {
    // background: linear-gradient(180deg, #07acbf, #34fdd3);
    background: rgb(210, 210, 210);
    color: #000;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 2px 7px rgba(255, 255, 255, 0.05);

    #stat-bar {
      display: flex;
      align-items: center;
      position: relative;
      left: 80%;
      top: -12px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      img:nth-child(1) {
        width: 18px;
        height: 8px;
      }

      img:nth-child(3) {
        width: 20px;
      }
    }

    #notch {
      background: $body-color;
      height: 25px;
      width: 55.7%;
      margin: auto;
      border-radius: 0px 0px 20px 20px;

      #speaker {
        width: 22%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4),
          rgba(255, 255, 255, 0.4)
        );
        height: 4px;
        border-radius: 5px;
        margin: auto;
        position: relative;
        top: 6px;
      }
    }

    #content {
      color: #000;
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 20px;
      }
      #lock-icon {
        width: 30px;
        margin-top: 24px;
      }

      #time {
        color: white;
        font-size: 70px;
        margin-top: 8px;
      }

      #date {
        color: white;
        font-size: 16px;
      }
    }

    #actions {
      width: 85%;
      display: flex;
      justify-content: space-between;
      position: relative;
      top: 64%;
      margin: auto;

      .action-bg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1s;

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.4);
        }

        img {
          width: 24px;
        }
      }

      .action-bg:nth-child(1) {
        img {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  #container {
    flex-direction: column;
  }

  #settings {
    width: 100%;
  }
}
</style>
